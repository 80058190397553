import React from 'react';
import './App.css';

function App() {
  return (
      <main className="h-screen flex items-center justify-center">
          <section className="py-24 relative">
              <div className="w-full max-w-7xl px-4 md:px-5 lg:px-5 mx-auto">
                  <div className="w-full flex-col justify-center items-center lg:gap-14 gap-10 inline-flex">

                      <div className="w-full flex-col justify-center items-center gap-5 flex">
                          <div className="w-full flex-col justify-center items-center gap-6 flex">
                              <div className="w-full flex-col justify-start items-center gap-2.5 flex">
                                  <h2 className="text-center text-white text-3xl font-bold font-manrope leading-normal">Please bear with us! We're currently under maintenance.</h2>
                                  <p className="text-center text-gray-500 text-base font-normal leading-relaxed">It's going to take some time to fix the error. We'll be back online soon.</p>
                              </div>

                              {/*<div className="w-full justify-center items-center gap-2.5 flex sm:flex-row flex-col">*/}
                              {/*    <input type="text"*/}
                              {/*           className="sm:w-80 w-full h-10 focus:outline-none text-gray-900 placeholder-gray-400 text-sm font-normal leading-relaxed px-3.5 py-2 rounded-lg shadow-[0px_1px_2px_0px_rgba(16,_24,_40,_0.05)] border border-gray-300"*/}
                              {/*           placeholder="Your mail"/>*/}
                              {/*    <button*/}
                              {/*        className="sm:w-fit w-full px-3.5 py-2 bg-indigo-700 hover:bg-indigo-800 transition-all duration-700 ease-in-out rounded-lg shadow-[0px_1px_2px_0px_rgba(16,_24,_40,_0.05)] justify-center items-center flex">*/}
                              {/*        <span*/}
                              {/*            className="px-1.5 text-white text-sm font-medium leading-6 whitespace-nowrap">Notify Me</span>*/}
                              {/*    </button>*/}
                              {/*</div>*/}
                          </div>
                          <img src="https://pagedone.io/asset/uploads/1718004199.png" alt="under maintenance image" className="object-cover"/>
                      </div>
                  </div>
              </div>
          </section>
      </main>
  );
}

export default App;
